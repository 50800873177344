.places {
    /* max-height: calc(100vh - 184px); */
    max-height: calc(100vh - 217px);
    overflow-y: scroll;
}

.place {
    cursor: pointer;
}

.place:hover {
    background: #9a9a9a;
}

.active {
    background: #e9e9e0;
}
