.tableOfContents {
    max-height: calc(100vh - 207px);
    overflow-y: scroll;
}

.chapters {
}

.chapter {
    cursor: pointer;
}

.chapter:hover {
    background: #9a9a9a;
}

.active {
    background: #e9e9e0;
}
