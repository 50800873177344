.footnote {
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.footnote:nth-child(2) {
    margin-top: 0;
}

.wrapper {
    padding: 0.25rem;
}

.current {
    background: #e1e1e1;
}

.index {
    float: left;
    margin-right: 0.25rem;
}

.paragraph:nth-child(2) {
    margin-top: 0;
}

.paragraph:last-child {
    margin-bottom: 0
}
