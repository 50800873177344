.chapter {
    text-align: justify;
}

.title {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    text-align: center;
}

.subtitle {
    font-size: 1.25rem;
    font-weight: normal;
    text-align: center;
}

.contents {
}
