.bibliographyEntry {
}

.authors {
}

.title {
    font-style: italic;
}

.location {
}

.year {
}

.edition {
}

.links {
}

.link {
}
