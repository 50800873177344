.threeColumnPageTemplate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.header {
    grid-column: 1 / 5;
}

.leftContent {
    grid-column: 1;
}

.centerContent {
    grid-column: 2 / 4;
}

.rightContent {
    grid-column: 4 / 5;
}

.footer {
    font-size: 0.5rem;
    grid-column: 1 / 5;
    grid-row: 3 / 4;
    padding: 1rem 1rem 0 1rem;
}
