.header {
    text-align: center;
}

.title {
    font-size: 2.0rem;
    margin: 0.5rem 0 0 0;
}

.subtitle {
    font-size: 1.25rem;
    margin: 0.5rem 0 0 0;
}

.mainNav {
    margin: 0.5rem 0 0 0;
}
